import React, { Component } from 'react'
import styled from 'styled-components'
import { theme, media } from '../../styles'

const Container = styled.div`
  margin: 0 0 30px 0;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const HeaderText = styled.p.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  font-family: ${theme.fonts.heading};
  font-size: ${theme.fontSizes.xLarge};
  font-weight: 100;
  margin: 0;
  cursor: pointer;
  ${media.phone`
    font-size: ${theme.fontSizes.small};
  `}
`

const Content = styled.div``

export default class Accordian extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hidden: true,
      symbol: '+',
    }

    this.toggleDisplay = this.toggleDisplay.bind(this)
  }

  componentDidMount() {
    const { hideInitially } = this.props
    if (hideInitially !== undefined) {
      let symbol = ''
      if (hideInitially) {
        symbol = '+'
      } else {
        symbol = '-'
      }
      this.setState({ hidden: hideInitially, symbol })
    }
  }

  toggleDisplay() {
    const { hidden } = this.state
    let symbol = ''
    if (hidden) {
      symbol = '-'
    } else {
      symbol = '+'
    }
    this.setState({ hidden: !hidden, symbol })
  }

  render() {
    const { title, children } = this.props
    const { hidden, symbol } = this.state
    const { colors: { text } } = theme

    return (
      <Container>
        <Header onClick={this.toggleDisplay}>
          <HeaderText shade={text}>{title}</HeaderText>
          <HeaderText shade={text}>{symbol}</HeaderText>
        </Header>
        {!hidden &&
          <Content>
            {children}
          </Content>}
      </Container>
    )
  }
}

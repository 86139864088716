import React from 'react'
import { connect } from 'react-redux'
import { TimelineMax } from 'gsap/all'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import * as Actions from '../state/app'
import ScrollMagicContext from '../components/ScrollMagic/ScrollMagicContext'
import ScrollMagic from '../components/ScrollMagic/ScrollMagic'
import Accordian from '../components/Utility/Accordian'
import { theme, media } from '../styles'
const { fonts, fontSizes } = theme

// const ScrollDown = styled.div`
//   position: fixed;
//   left: 50%;
//   bottom: 0;
//   z-index: 99;
// `

const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  ${media.thone`
    padding: 100px 0 50px 0;
  `}
`

const Title = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  h2 {
    color: ${props => props.color};
    font-family: ${fonts.accent};
    font-size: ${fontSizes.largest};
    font-weight: 100;
    letter-spacing: 2px;
    margin:0;
  }
  ${media.thone`
    h2 {
      font-size: ${fontSizes.superbig};
      }
  `}
`

const Photos = styled.div`
  display: grid;
  width: 60%;
  max-width: 80%;
  grid-template-columns: 5% 42.5% 5% 42.5% 5%;
  grid-template-rows: 3% auto 5% auto 5% auto 7%;
  grid-template-areas:
    ". border border border ."
    ". one . two ."
    ". . . . ."
    ". three . four ."
    ". . . . ."
    ". five . six .";
  ${media.bigDesktop`
    width: 75%;
  `}
  ${media.desktop`
      width: 100%;
  `}
`

const Border = styled.div`
  grid-area: border;
  border-top: 4px dotted #f4e8d0c2;
  ${media.thone`
    border-top: transparent;
  `}
`

const AreaOne = styled.div`
  grid-area: one;
  overflow: hidden;
`

const AreaThree = styled.div`
  grid-area: three;
  overflow: hidden;
`

const AreaFour = styled.div`
  grid-area: four;
  overflow: hidden;
`

const AreaFive = styled.div`
  grid-area: five;
  align-self: flex-end;
  overflow: hidden;
`

const AreaSix = styled.div`
  grid-area: six;
  overflow: hidden;
`

const SpanTextContainer = styled.div`
  font-size: ${fontSizes.xxLarge};
  line-height: 2;
  font-weight: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${media.tablet`
    font-size: ${fontSizes.medium};
    line-height: 1.5;
  `}
  ${media.phone`
    font-size: ${fontSizes.small};
  `}
`

const SpanLabel = styled.span.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  font-family: ${fonts.heading};
  text-decoration: underline;
  color: ${props => props.color};
  // text-transform: uppercase;
  // &:after {
  //   content: ' / ';
  // }
`

const SpanText = styled.span.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  font-family: ${fonts.heading};
  color: ${props => props.color};
  &:before {
    content: ' ';
  }
`

const QuoteOuter = styled.div`
  display: grid;
  grid-template-columns: 5% 10% auto 5%;
  grid-template-rows: 100%;
  grid-template-areas: ". block text .";
  width: 60%;
  max-width: 80%;
  margin: 30px 0;
  ${media.bigDesktop`
    width: 75%;
  `}
  ${media.desktop`
      width: 100%;
  `}
`

const QuoteBlock = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  grid-area: block;
  background-color: ${props => props.color};
  height: 100px;
  width: 35%;
  align-self: flex-start;
  ${media.tablet`
    width: 50%;
    height: 80px;
  `}
`

const QuoteText = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  grid-area: text;
  color: ${props => props.color};
  p {
    font-family: ${fonts.body};
    font-size: ${fontSizes.h3};
    line-height: 1.5;
    letter-spacing: 1px;
    margin: 0;
  }
  ${media.thone`
    p {
      font-size: ${fontSizes.large};
      line-height: 1.7;
    }
  `}
`

const PanelContainer = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  width: 60%;
  max-width: 80%;
  display: grid;
  grid-template-columns: 5% 35% auto 5%;
  grid-template-rows: 100%;
  grid-template-areas: ". left right .";
  ${media.bigDesktop`
    width: 75%;
  `}
  ${media.desktop`
      width: 100%;
  `}
  ${media.thone`
    grid-template-rows: auto auto;
    grid-template-areas:
      ". left left ."
      ". right right .";
  `}
`

const ButtonOuter = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  grid-area: left;
  ${media.thone`
    margin: 30px 0 40px 0;
  `}
`

const Button = styled.div.attrs(({ shade, text }) => ({
  color: shade || '#FFFFFF',
  text: text || '#FFFFFF',
}))`
  cursor: pointer;
  background: ${props => props.color};
  padding: 20px 30px;
  z-index: 60;
  display: inline-block;

  p {
    margin: 0;
    color: ${props => props.text};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-family: ${theme.fonts.body};
    font-size: ${theme.fontSizes.medium};
  }
`

const TextBox = styled.div.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  width: 100%;
  grid-area: right;
  justify-self: flex-end;
  ${media.thone`
    justify-self: flex-start;
  `}
`

const DetailsList = styled.ul.attrs(({ shade }) => ({
  color: shade || '#FFFFFF',
}))`
  color: ${props => props.color};
  padding-inline-start: 0px;
  list-style: none;
  margin-left: 0;
  padding-left: 1em;
  width: auto;
  margin: 20px 0 30px 0;
  li {
    font-family: ${theme.fonts.heading};
    font-size: ${theme.fontSizes.small};
    font-weight: 100;
    text-align: justify;
    line-height: 1.7;
  }
  li {
    &:before {
      display: inline-block;
      content: "-";
      width: 1em;
      margin-left: -1em;
    }
  }
`

export class ArtTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      opened: false
    }

    this.scrollDown = null
    // this.hideScroll = this.hideScroll.bind(this)
  }

  // hideScroll() {
  //   this.animation = new TimelineMax({ paused: true })
  //     .set(this.scrollDown, { css: { animation: 'none' } })
  //     .to(this.scrollDown, 2, { y: 60, opacity: 0, delay: 1, display: 'none' })
  //     .play()
  // }

  render() {
    const { colors: { text, light, dark, shadow, accent } } = theme
    const { data } = this.props
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark
    const { title, subtitle, detailedshots, element, quality, ruler } = frontmatter

    return (
      <ScrollMagicContext
        location={`art/${title}`}
        vertical={true}
      >
        {/* <ScrollDown ref={ref => { this.scrollDown = ref }}>
          <ScrollMagic component={`SCROLL_DOWN`} />
        </ScrollDown> */}
        <Container>
          <Title shade={text}>
            <h2>{`>smoking ${title}<`}</h2>
          </Title>
          <QuoteOuter>
            <QuoteBlock shade={accent} />
            <QuoteText shade={text}>
              <div dangerouslySetInnerHTML={{ __html: html }} />
            </QuoteText>
          </QuoteOuter>
          <Photos>
            <AreaOne>
              <ScrollMagic
                component={`REVEAL_IMAGE`}
                image={detailedshots[0].childImageSharp.fluid}
                callback={this.hideScroll}
              />
            </AreaOne>
            <AreaThree>
              <ScrollMagic
                component={`REVEAL_IMAGE`}
                image={detailedshots[1].childImageSharp.fluid}
              />
            </AreaThree>
            <AreaFour>
              <ScrollMagic
                component={`REVEAL_IMAGE`}
                image={detailedshots[2].childImageSharp.fluid}
              />
            </AreaFour>
            <AreaFive>
              <SpanTextContainer>
                <SpanLabel shade={text}>element</SpanLabel>
                <SpanText shade={text}>{element}</SpanText>
              </SpanTextContainer>
              <SpanTextContainer>
                <SpanLabel shade={text}>quality</SpanLabel>
                <SpanText shade={text}>{quality}</SpanText>
              </SpanTextContainer>
              <SpanTextContainer>
                <SpanLabel shade={text}>ruler</SpanLabel>
                <SpanText shade={text}>{ruler}</SpanText>
              </SpanTextContainer>
            </AreaFive>
            <AreaSix>
              <ScrollMagic
                component={`REVEAL_IMAGE`}
                image={detailedshots[3].childImageSharp.fluid}
              />
            </AreaSix>
          </Photos>
          <PanelContainer shade={text}>
            <ButtonOuter>
              <AniLink to="/request" swipe="true" direction="up">
                <Button shade={dark} text={light}>
                  <p>order</p>
                </Button>
              </AniLink>
            </ButtonOuter>
            <TextBox shade={text}>
              <Accordian title={`product details`} hideInitially={false}>
                <DetailsList shade={text}>
                  {/* <li>{subtitle}</li> */}
                  <li>Acrylic on 100% cotton canvas</li>
                  <li>Unframed</li>
                  <li>Every painting is hand painted by Estelle and is unique and irreplicable</li>
                  <li>Option for custom art and requests</li>
                </DetailsList>
              </Accordian>
              <Accordian title={`fit / size`} hideInitially={false}>
                <DetailsList shade={text}>
                  <li>One size</li>
                  <li>Gender neutral</li>
                  <li>25.5 cm x 42 cm</li>
                </DetailsList>
              </Accordian>
            </TextBox>
          </PanelContainer>
        </Container>
      </ScrollMagicContext>
    )
  }
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        subtitle
        element
        quality
        ruler
        detailedshots {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

function mapStateToProps({ displayNav }) {
  return { displayNav }
}

function mapDispatchToProps(dispatch) {
  return {
    toggle: () => { dispatch(Actions.toggleNav()) },
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ArtTemplate)
